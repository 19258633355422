import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import Navigation from '../components/Navigation'
import Vs from '../components/vs'
import style from './PastEvents.module.css'

const PastEvents = ({ data }) => {
  const events = [
    {
      title: 'Dawn 2019',
      venue: 'Festival Grounds, Aliganj, Lucknow, UP',
      date: ' Monday, January 07, 2019',
      link: 'https://www.tapology.com/fightcenter/events/57813-dawn-2019',
      fights: [
        {
          image1: '',
          name1: 'Shikhar Tripathi',
          description1: 'India',
          image2: '',
          name2: 'M. Khan',
          description2: 'India',
          weight: '55',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: '',
          name1: 'R. Singh',
          description1: 'India',
          image2: '',
          name2: 'Akash Singh',
          description2: 'India',
          weight: '60',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: '',
          name1: 'Partho Ghosh',
          description1: 'India',
          image2: '',
          name2: 'Sunny Maurya',
          description2: 'India',
          weight: '60',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: '',
          name1: 'Satyam Arora',
          description1: 'India',
          image2: '',
          name2: 'Qayyum Khan',
          description2: 'India',
          weight: '75',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: '',
          name1: 'Fahad Khan',
          description1: 'India',
          image2: '',
          name2: 'Om Gauswami',
          description2: 'India',
          weight: '52',
          cat: 'men',
          score: '1-0',
        },
      ],
    },
    {
      title: 'Rise of Wrestling',
      venue: 'Smriti Upvan, Bangla Bazaar, Aashiyana, Lucknow, UP',
      date: 'Monday, December 03, 2018',
      link:
        'https://www.tapology.com/fightcenter/events/56804-rise-of-wrestling-day-2',
      fights: [
        {
          image1: '',
          name1: 'Partho Ghosh',
          description1: 'India',
          image2: '',
          name2: 'Shailendra Kumar',
          description2: 'India',
          weight: '60',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: 'niyaesh',
          name1: 'Niyaesh Marouf Arween (Afghanistan)',
          description1: 'Afghanistan',
          image2: '',
          name2: 'Suman Singh (India)',
          description2: 'India',
          weight: '48',
          cat: 'women',
          score: '1-0',
        },
        {
          image1: '',
          name1: 'Muhammad Rahimi (Afghanistan)',
          description1: 'Afghanistan',
          image2: '',
          name2: 'Anurag Rai (India)',
          description2: 'India',
          weight: '75',
          cat: 'men',
          score: '1-0',
        },
        {
          image1: 'balveer',
          name1: 'Balveer Singh Tomar (India)',
          description1: 'India',
          image2: 'rasuli',
          name2: 'Ningyali Rasuli (Afghanistan)',
          description2: 'Afghanistan',
          weight: '60',
          cat: 'men',
          score: '1-0',
        },
      ],
    },
  ]

  return (
    <Layout>
      <Navigation />
      <div className={style.container}>
        {events.map(event => (
          <div className={style.event}>
            <h2>{event.title}</h2>
            <p>
              <span className={style.bold}>Venue: </span>
              {event.venue}
            </p>
            <p>
              <span className={style.bold}>Date: </span>
              {event.date}
            </p>
            <h3>
              Fight Card
            </h3>
            <div className={style.fights}>
              {event.fights.map(fight => (
                <Vs
                  {...fight}
                  image1={
                    fight.image1
                      ? data[fight.image1].childImageSharp.fluid
                      : data.player.childImageSharp.fluid
                  }
                  image2={
                    fight.image2
                      ? data[fight.image2].childImageSharp.fluid
                      : data.player.childImageSharp.fluid
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default PastEvents

export const query = graphql`
  query {
    niyaesh: file(relativePath: { regex: "/niyaesh.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    balveer: file(relativePath: { regex: "/balveer.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    rasuli: file(relativePath: { regex: "/rasuli.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    player: file(relativePath: { regex: "/player.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
